import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Color from "color";
import Img from "gatsby-image";

import theme from "../../theme/theme";
import { type } from "../../theme/typography";

import Layout from "../../components/layout";
import Container from "../../components/container";
import Content from "../../components/markdown-content";
import SEO from "../../components/seo";

import IconEmail from "../../images/icon-email.svg";
import IconTwitter from "../../images/icon-twitter.svg";
import IconGithub from "../../images/icon-github.svg";


const HeaderImageContainer = styled(Container)`
  margin-top: ${theme.spacing.small};

  ${theme.breakpoint.down("large")`
    margin: 0;
    padding: 0;
  `};
`;

const HeaderImage = styled(Img)`
  margin-bottom: ${theme.spacing.xlarge};
  max-width: 1100px;
  margin: 0 auto;
  flex: 0 0 100%;
  min-height: 280px;
  max-height: 520px;
  img {
    object-position: center bottom !important;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${theme.spacing.xlarge};
  ${theme.breakpoint.down("small")`
    margin-top: ${theme.spacing.base};
  `}
`;

const IconLink = styled.div`
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    transform: translateY(4px);
  }
`;

const ContactLinks = styled.div`
  margin: ${theme.spacing.small} 0;
  ${type("body")};
  ${theme.breakpoint.down("small")`
      ${type("bodySmall")};
  `};
  a {
    /* border: none; */
    //font-weight: 400;
  }
`;

const Bio = styled(Content)`
  flex: 2 1 0%;
  p + p,
  p + blockquote,
  blockquote + p,
  blockquote + blockquote,
  p + ul,
  ul + p,
  p + ol,
  ol + p {
    margin-top: 22px;
  }
  blockquote {
    margin-left: 0;
  }
  hr {
    margin: ${theme.spacing.base} auto;
  }
  ${theme.breakpoint.down("large")`
    flex: 1 0 100%;
  `};
`;

const Contact = styled(Content)`
  p {
    ${type("body")};
  }
  hr {
    display: none;
    margin: ${theme.spacing.base} auto;
  }
  color: ${props => props.bodyColor};
  flex: 1.2 1 0%;
  margin-left: ${theme.spacing.large};
  ${theme.breakpoint.down("large")`
    flex: 1 0 100%;
    margin-left: 0;
    hr {
      display: block;
    }
  `};
  ${theme.breakpoint.down("small")`
    p {
      ${type("bodySmall")};
    };
  `};
`;

const BorderBox = styled.div`
  padding-left: ${theme.spacing.large};
  border-left: 1px solid ${props =>
    Color(props.backgroundColor).isLight()
      ? Color(props.footerColor)
          .fade(0.8)
          .string()
      : Color(props.footerColor)
          .fade(0.5)
          .string()};};
  ${theme.breakpoint.down("large")`
    border: none;
    padding-left: 0;
  `};
`;

export default ({ data }) => {
  const postArray = data.allMarkdownRemark.edges
    .filter(({ node }) => node.frontmatter.title === "about-workhistory")
    .map(({ node }) => node);

  const post = postArray[0];
  const image = post.frontmatter.cardImage
    ? post.frontmatter.cardImage.childImageSharp.resize
    : null;

  return (
    <React.Fragment>
      <SEO
        title="About"
        description="I'm a product leader, designer, and founder in California."
        image={image}
      />

      <Layout
        bodyColor={post.frontmatter.bodyColor}
        backgroundColor={post.frontmatter.backgroundColor}
        navColor={post.frontmatter.navColor}
        footerColor={post.frontmatter.footerColor}
      >
        <HeaderImageContainer>
          <HeaderImage
            fluid={post.frontmatter.headerImage.childImageSharp.fluid}
            alt="Portrait of Ryan Lucas"
          />
        </HeaderImageContainer>
        <StyledContainer>
          <Bio
            backgroundColor={post.frontmatter.backgroundColor}
            bodyColor={post.frontmatter.bodyColor}
            highlightColor={post.frontmatter.highlightColor}
            footerColor={post.frontmatter.footerColor}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <Contact
            bodyColor={post.frontmatter.bodyColor}
            backgroundColor={post.frontmatter.backgroundColor}
            highlightColor={post.frontmatter.highlightColor}
            footerColor={post.frontmatter.footerColor}
          >
            <hr />
            <BorderBox
              backgroundColor={post.frontmatter.backgroundColor}
              footerColor={post.frontmatter.footerColor}
            >
              <p>
                I love meeting new people and sharing ideas, so please say
                hello:
              </p>
              <ContactLinks>
                <IconLink>
                  <IconEmail />
                  <a href="mailto:ryan@ryanlucas.org?subject=👋">
                    ryan@ryanlucas.org
                  </a>
                </IconLink>
                <IconLink>
                  <IconTwitter />
                  <a href="https://twitter.com/ryanlucas">Twitter</a>
                </IconLink>
                <IconLink>
                  <IconGithub />
                  <a href="https://github.com/ryanlucas">GitHub</a>
                </IconLink>
              </ContactLinks>
              <p>
                I'm always up for chatting about product development and
                management, computational tools for
                creative thinking, and design history and pedagogy.
              </p>
            
            </BorderBox>
          </Contact>
        </StyledContainer>
      </Layout>
    </React.Fragment>
  );
};

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1052) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { parentPage: { eq: "about" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          html
          frontmatter {
            title
            parentPage
            backgroundColor
            bodyColor
            highlightColor
            footerColor
            navColor
            headerImage {
              childImageSharp {
                fluid(maxWidth: 1000, srcSetBreakpoints: [1000, 2000]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            cardImage {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
