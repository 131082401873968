// (Primarily typographic) styles for main content area of markdown.
// Used for work and writing templates.

import React from "react";
import styled from "styled-components";
import Color from "color";

import theme from "../theme/theme";
import { type } from "../theme/typography";

const Content = styled.div`
  a {
    color: ${props => props.bodyColor};
    border-color: ${props => props.highlightColor};
    &:hover {
      background-color: ${props =>
        Color(props.highlightColor)
          .fade(0.9)
          .string()};
    }
  }

  p,
  ul,
  ol {
    ${type("body")};
    color: ${props => props.bodyColor};
    ${theme.breakpoint.down("small")`
      ${type("bodySmall")};
    `};
  }

  p:last-child {
    margin-bottom: 0;
  }

  p + p,
  p + blockquote,
  blockquote + p,
  blockquote + blockquote,
  p + ul,
  ul + p,
  p + ol,
  ol + p {
    margin-top: 28px;
  }

  p + h2,
  p + h3,
  div + h2,
  figure + h2,
  div + h3,
  figure + h3 {
    margin-top: ${theme.spacing.large};
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    margin-left: 22px;
  }

  ul {
    list-style: circle;
  }

  li {
    margin-top: 12px;
    padding-left: 8px;
    p {
      margin-top: 22px;
    }
  }

  h2 {
    ${type("header2")};
    color: ${props => Color(props.bodyColor).string()};
    margin-bottom: ${theme.spacing.xsmall};
    margin-left: auto;
    margin-right: auto;
    flex: 1 1 100%;
    ${theme.breakpoint.down("small")`
      ${type("header2mobile")};
    `};
  }

  h3 {
    ${type("header3")};
    color: ${props => Color(props.bodyColor).string()};
    margin-bottom: 10px;
    ${theme.breakpoint.down("small")`
      ${type("header3mobile")};
    `};
  }

  blockquote {
    font-style: italic;
    border-left: 2px solid ${props => props.highlightColor};
    padding-left: 21px;
    padding-top: 12px;
    padding-bottom: 14px;
    padding-right: 20px;
    margin-left: -24px;
    margin-right: 0;
    margin-bottom: 0;
    background: ${props =>
      Color(props.backgroundColor).isLight()
        ? Color(props.backgroundColor)
            .darken(0.03)
            .string()
        : Color(props.backgroundColor)
            .lighten(0.2)
            .string()};
    &:first-child {
      margin-top: 0;
    }
    ${theme.breakpoint.down("small")`
      margin-left: 0;
    `};
  }

  figcaption {
    ${type("caption")};
    color: ${props =>
      Color(props.bodyColor)
        .fade(0.3)
        .string()};
    text-align: center;
    margin: 10px 0 0 0;
  }

  code {
    background: ${props =>
      Color(props.bodyColor)
        .lighten(0.8)
        .fade(0.9)
        .string()};
    border-radius: 4px;
    padding: 1px 2px;
  }

  hr {
    height: 1px;
    border: none;
    background: ${props =>
      Color(props.backgroundColor).isLight()
        ? Color(props.footerColor)
            .fade(0.8)
            .string()
        : Color(props.footerColor)
            .fade(0.5)
            .string()};
    margin: ${theme.spacing.xlarge} auto;
    width: 50%;
    ${theme.breakpoint.down("medium")`
      margin: ${theme.spacing.base} auto;
    `};
  }

  .footnotes {
    margin-left: auto;
    margin-right: auto;
    padding: 0 ${theme.variables.sitePadding + "px"};
    ${theme.breakpoint.down("medium")`
      padding: 0 ${theme.spacing.xlarge};
    `};
    ${theme.breakpoint.down("small")`
      padding: 0 ${theme.spacing.small};
    `};
    max-width: ${parseFloat(theme.variables.siteMeasure) +
      parseFloat(theme.variables.sitePadding * 2) +
      "px"};
    ol {
      margin: 0 0 0 18px;
      &:before {
        content: "Footnotes";
        ${type("header3")};
        margin-left: -18px;
      }
    }
    p {
      ${type("bodySmall")};
      &:first-child {
        margin-top: 0;
      }
    }
    li {
      ${type("bodySmall")};
    }
    li p {
      margin-top: 18px;
    }
  }
  .footnote-ref {
    ${type("footnoteSuperscript")};
    border: none;
    color: ${props =>
      Color(props.highlightColor)
        .darken(0.2)
        .string()};
    padding: 0 2px;
  }
  .footnote-backref {
    border: none;
    color: transparent;
    position: relative;
    &:after {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path fill="${props =>
        Color(props.highlightColor)
          .darken(0.1)
          .rgb()
          .string()}" d="M12 5.44L6 0 0 5.44 1.5 7 5 3.5V12h2V3.5L10.5 7 12 5.44z"/></svg>') no-repeat center center;
    }
  }
  // Note: due to styled-components weird psuedo class parsing, putting stuff below here will not parse.
`;

export default props => <Content {...props}>{props.children}</Content>;
